// Generated by CoffeeScript 1.11.1
(function() {
  var compareRoutes, qs,
    extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  qs = require('qs');

  compareRoutes = function(a, b) {
    if (a === b) {
      return 0;
    } else if (a === '*') {
      return -1;
    } else if (b === '*') {
      return 1;
    } else if (a > b) {
      return -1;
    } else {
      return 1;
    }
  };

  module.exports = function(app) {
    var PageController;
    if (app._pages == null) {
      app._pages = {};
    }
    app.Page.prototype.setClass = function(cls) {
      var k, ref, results, v;
      if (this.__proto__) {
        return this.__proto__ = cls.prototype;
      } else {
        ref = cls.prototype;
        results = [];
        for (k in ref) {
          v = ref[k];
          if (v && this[k] !== v) {
            results.push(this[k] = v);
          } else {
            results.push(void 0);
          }
        }
        return results;
      }
    };
    app.on('ready', function(page) {
      var ns;
      ns = page.model.get('$render.ns');
      if (ns in app._pages) {
        return page.setClass(app._pages[ns]);
      }
    });
    app.controller = function(cls) {
      var controller, i, k, len, oldPrototype, path, paths, v;
      if (!(cls.prototype instanceof PageController)) {
        oldPrototype = cls.prototype;
        cls.prototype = new PageController;
        cls.prototype._init = oldPrototype.init;
        delete oldPrototype.init;
        for (k in oldPrototype) {
          v = oldPrototype[k];
          cls.prototype[k] = v;
        }
      }
      controller = function(page, model, params, next) {
        var handler, path, ref;
        this.setClass(cls);
        if (this.$defaultQuery && !Object.keys(this.params.query).length) {
          model.set('$render.params.query', this.$defaultQuery());
        }
        ref = this.$params || {};
        for (path in ref) {
          handler = ref[path];
          path = "$render.params." + path;
          if (typeof handler === 'string') {
            handler = this.$paramTypes[handler];
          }
          model.setDiff(path, handler.call(this, model.get(path)));
        }
        model.set('_ownRefs', []);
        return this.$subscribe(this.$model, (function(_this) {
          return function(err) {
            if (err) {
              return next(err);
            }
            return _this.$render(next);
          };
        })(this));
      };
      if (cls.prototype.path) {
        paths = typeof cls.prototype.path === 'string' ? [cls.prototype.path] : cls.prototype.path;
        for (i = 0, len = paths.length; i < len; i++) {
          path = paths[i];
          app.get(path, controller);
          if (cls.prototype.handlePost) {
            app.post(path, controller);
          }
        }
        if (app.tracksRoutes) {
          app.tracksRoutes.sort(function(a, b) {
            return compareRoutes(a[1], b[1]);
          });
        } else {
          app.history.routes.queue.get.sort(function(a, b) {
            return compareRoutes(a.path, b.path);
          });
        }
      }
      if (cls.prototype.view) {
        app.loadViews(cls.prototype.view, cls.prototype.name);
      }
      if (cls.prototype.name) {
        app._pages[cls.prototype.name] = cls;
      }
      if (cls.prototype.register) {
        return cls.prototype.register(app);
      }
    };
    return module.exports.PageController = PageController = (function(superClass) {
      extend(PageController, superClass);

      function PageController() {}

      PageController.prototype.$paramTypes = {
        number: function(v) {
          if (v) {
            return Number(v);
          }
        },
        boolean: function(v) {
          return v === 'true';
        }
      };

      PageController.prototype.$subscribe = function($model, next) {
        var $wrapper, copy, name, params, q, query, ref, refs, subscriptions;
        if (!$model) {
          return next();
        }
        if (typeof $model === 'function') {
          return this.$subscribe($model.call(this), next);
        }
        if (Array.isArray($model)) {
          if (!$model.length) {
            return next();
          }
          return this.$subscribe($model[0], (function(_this) {
            return function(err) {
              if (err) {
                return next(err);
              }
              return _this.$subscribe($model.slice(1), next);
            };
          })(this));
        }
        subscriptions = [];
        refs = {};
        for (name in $model) {
          query = $model[name];
          $wrapper = this.model.at(name);
          if (typeof query === 'object' && '$required' in query) {
            this.model.push('$required', name);
          }
          if (query === void 0) {

          } else if (typeof query === 'string') {
            refs[name] = this.model.root.at(query);
            subscriptions.push(query);
          } else if ('$path' in query) {
            refs[name] = this.model.root.at(query.$path);
            subscriptions.push(query.$path);
          } else if ('$copy' in query) {
            copy = this.model.getDeepCopy(query.$copy);
            $wrapper.set(copy);
          } else if ('$set' in query) {
            $wrapper.set(query.$set);
          } else if ('$setNull' in query) {
            $wrapper.setNull(query.$setNull);
          } else if ('$filter' in query) {
            q = this.model.root.filter(query.$collection, query.$filter);
            if ('$sort' in query) {
              q = q.sort(query.$sort);
            }
            q.ref($wrapper);
          } else if ('$sort' in query) {
            this.model.root.sort(query.$collection, query.$sort).ref($wrapper);
          } else if ('$ref' in query) {
            refs[name] = query.$ref;
          } else if ('$start' in query) {
            (ref = this.model).start.apply(ref, [$wrapper].concat(slice.call(query.$start)));
          } else {
            if ('$ids' in query) {
              $wrapper = this.model.root.query(query.$collection, '_page.' + query.$ids);
            } else if ('$serverQuery' in query) {
              params = Object.assign({}, query);
              delete params.$collection;
              delete params.$serverQuery;
              $wrapper = this.model.root.serverQuery(query.$collection, query.$serverQuery, params);
            } else {
              params = Object.assign({}, query);
              delete params.$collection;
              $wrapper = this.model.root.query(query.$collection, params);
            }
            this.model.push('_queries', {
              path: name,
              hash: $wrapper.hash
            });
            refs[name] = $wrapper;
            subscriptions.push($wrapper);
          }
          this[name] = $wrapper;
        }
        return this.model.root.subscribe(subscriptions, (function(_this) {
          return function(err) {
            var i, len, ref1, ref2, ref3, ref4, rootPath;
            if (err) {
              return next(err);
            }
            for (name in refs) {
              query = refs[name];
              rootPath = '_page.' + name;
              if (((ref1 = query.expression) != null ? ref1.$distinct : void 0) || ((ref2 = query.expression) != null ? ref2.$count : void 0) || ((ref3 = query.expression) != null ? ref3.$aggregate : void 0)) {
                if (rootPath in _this.model.root._refLists.fromMap) {
                  _this.model.removeRef(name);
                }
                query.refExtra(_this.model.at(name));
              } else {
                if (query.expression) {
                  if (rootPath in _this.model.root._refs.fromMap) {
                    _this.model.removeRef(name);
                  }
                } else {
                  if (rootPath in _this.model.root._refLists.fromMap) {
                    _this.model.removeRef(name);
                  }
                }
                _this.model.ref(name, query);
              }
              _this.model.push('_ownRefs', name);
            }
            ref4 = _this.model.get('$required') || [];
            for (i = 0, len = ref4.length; i < len; i++) {
              name = ref4[i];
              if (!_this[name].get()) {
                return next(404);
              }
            }
            return next();
          };
        })(this));
      };

      PageController.prototype.$render = function(next) {
        if (!this.name) {
          return next();
        }
        if (this["static"]) {
          return this.page.renderStatic(this.name);
        } else {
          return this.page.render(this.name);
        }
      };

      PageController.prototype.init = function(model) {
        var i, len, name, ref;
        ref = this.root.get('_page.$required') || [];
        for (i = 0, len = ref.length; i < len; i++) {
          name = ref[i];
          this.model.on('change', name, (function(_this) {
            return function(value) {
              if (!value) {
                return _this.app.history.refresh();
              }
            };
          })(this));
        }
        if (typeof this._init === "function") {
          this._init(model);
        }
        return this.on('create', function() {
          return this.model.on('change', 'params.query**', (function(_this) {
            return function(path) {
              var j, k, len1, oldRefs, oldSubscriptions, q, query, ref1;
              if (path.startsWith('_')) {
                return;
              }
              query = _this.model.get('params.query');
              query = Object.assign({}, query);
              for (k in query) {
                if (k.startsWith('_')) {
                  delete query[k];
                }
              }
              _this.app.history.replace('?' + qs.stringify(query), (_this.onQueryChange == null) || _this.onQueryChange === 'rerender');
              if (_this.onQueryChange === 'resubscribe') {
                _this.model.root.set('_session.loading', true);
                oldSubscriptions = {};
                ref1 = _this.model.get('_queries');
                for (j = 0, len1 = ref1.length; j < len1; j++) {
                  q = ref1[j];
                  if (_this.model.get('_ownRefs').includes(q.path)) {
                    oldSubscriptions[q.path] = _this[q.path];
                  }
                }
                oldRefs = _this.model.get('_ownRefs');
                _this.model.set('_ownRefs', []);
                return _this.$subscribe(_this.$model, function(err) {
                  var l, len2;
                  for (l = 0, len2 = oldRefs.length; l < len2; l++) {
                    path = oldRefs[l];
                    if (!_this.model.get('_ownRefs').includes(path)) {
                      _this.model.removeRef(path);
                    }
                  }
                  for (name in oldSubscriptions) {
                    query = oldSubscriptions[name];
                    query.unsubscribe();
                  }
                  return _this.model.root.set('_session.loading', false);
                });
              }
            };
          })(this));
        });
      };

      return PageController;

    })(app.Page);
  };

}).call(this);
