// Generated by CoffeeScript 1.12.4
(function() {
  var indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

  module.exports = (function() {
    function _Class() {}

    _Class.prototype.view = __dirname;

    _Class.prototype.init = function() {
      var activePath, activeUrl, href, query, startsWith;
      startsWith = this.model.get('startsWith');
      activeUrl = this.model.root.get('$render.url');
      if (indexOf.call(activeUrl, '?') >= 0) {
        activePath = activeUrl.slice(0, +(activeUrl.indexOf('?') - 1) + 1 || 9e9);
      } else {
        activePath = activeUrl;
        activeUrl += '?';
      }
      href = encodeURI(this.getAttribute('href') || '') || activePath;
      query = this.model.get('query');
      if (query != null) {
        query = encodeURI(query);
        this.model.set('active', activeUrl === (href + "?" + query));
        this.model.set('url', href + "?" + query);
      } else {
        this.model.set('url', href);
        if (startsWith === 'starts-with') {
          this.model.set('active', (activePath + '/').startsWith(href + '/'));
        } else if (startsWith) {
          this.model.set('active', (activePath + '/').startsWith(startsWith + '/'));
        } else {
          this.model.set('active', activePath === href);
        }
      }
      if (!this.onUrlChange) {
        return this.onUrlChange = this.model.scope('$render').on('change', 'url', (function(_this) {
          return function() {
            return _this.init();
          };
        })(this));
      }
    };

    _Class.prototype.create = function() {
      if (this.getAttribute('replace')) {
        this.elem.setAttribute('data-router-ignore', '');
        return this.dom.on('click', this.elem, (function(_this) {
          return function(e) {
            e.preventDefault();
            return _this.app.history.replace(_this.model.get('url'));
          };
        })(this));
      }
    };

    return _Class;

  })();

}).call(this);
