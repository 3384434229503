module.exports = class SiteNode
    name: 'pages:default'

    controller: (next) ->
        @$subscribe @$model, (err) =>
#            return next() if err == 404
            return next err if err

#            if @render? next
#                return

#            return next() unless @name

#            if not @model.root.get '_session.superuser'
#                @page.renderStatic @name
#            else
            @page.render @name

    $model: ->
        content: "contents.#{@model.get 'node.contentId'}"
