genLinkList = (nodes, node=null) ->
    if not node
        node = Object.values(nodes).find (n) -> n.url == '/'

    children = node.children?.map (id) ->
        n = nodes[id]
        if n.children?.length
            title: n.title, menu: genLinkList nodes, n
        else
            title: n.title, value: n.url
    [title: node.title or 'Главная', value: node.url].concat children or []

# Strips HTML and PHP tags from a string
# returns 1: 'Kevin <b>van</b> <i>Zonneveld</i>'
# example 2: strip_tags('<p>Kevin <img src="someimage.png" onmouseover="someFunction()">van <i>Zonneveld</i></p>', '<p>');
# returns 2: '<p>Kevin van Zonneveld</p>'
# example 3: strip_tags("<a href='http://kevin.vanzonneveld.net'>Kevin van Zonneveld</a>", "<a>");
# returns 3: '<a href='http://kevin.vanzonneveld.net'>Kevin van Zonneveld</a>'
# example 4: strip_tags('1 < 5 5 > 1');
# returns 4: '1 < 5 5 > 1'
strip_tags = (str, allowed_tags) ->
    key = ''
    allowed = false
    matches = []
    allowed_array = []
    html = ''
    replacer = (search, replace, str) ->
        str.split(search).join replace

    # Build allowes tags associative array
    if allowed_tags
        allowed_array = allowed_tags.match /([a-zA-Z0-9]+)/gi
    str += ''

    # Match tags
    matches = str.match /(<\/?[\S][^>]*>)/gi
    # Go through all HTML tags
    for key, html of matches
        if isNaN key
            # IE7 Hack
            continue

        # Save HTML tag
        html = html.toString()
        # Is tag not in allowed list? Remove from str!
        allowed = false

        # Go through all allowed tags
        for allowed_tag in allowed_array  # Init
            i = -1

            if i != 0
                i = html.toLowerCase().indexOf '<' + allowed_tag + '>'
            if i != 0
                i = html.toLowerCase().indexOf '<' + allowed_tag + ' '
            if i != 0
                i = html.toLowerCase().indexOf '</' + allowed_tag

            # Determine
            if i == 0
                allowed = true
                break

        console.log 'allowed', key, allowed
        if not allowed
            str = replacer html, '', str  # Custom replace. No regexing
    str


module.exports = class
    create: ->
        @model.start '_setEditMode', @model.scope('_session.editMode'), (editMode) =>
            if not editMode
                @editor?.setContent @model.get('value') or ' '
                @editor?.destroy()
#                @editor?.setMode 'readonly'
#                @editor?.setContent @model.get('value') or ' '
#                @editor?._clickBlocker.unbind()
#                @editor?._clickBlocker = null
                return
#            return @editor.setMode 'design' if @editor
            @elem.id ||= tinymce.DOM.uniqueId()
            tinymce.init
                target: @elem
                inline: true
                theme: 'inlite'
                plugins: 'advlist code contextmenu hr image imagetools paste link lists table template'
                selection_toolbar: 'bold italic | link h2 h3 h4 aligncenter bullist numlist | code'
                insert_toolbar: 'template image table hr'
                advlist_bullet_styles: ''
                advlist_number_styles: ''
                formats:
                    alignleft:
                        selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img'
                        classes: 'align-left'
                    aligncenter:
                        block: 'div'
                        classes: 'align-center'
                        wrapper: true
                    alignright:
                        selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img'
                        classes: 'align-right'
#      alignfull: {selector : 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img', classes : 'full'},
#      bold: {inline : 'span', 'classes' : 'bold'},
#      italic: {inline : 'span', 'classes' : 'italic'},
#      underline: {inline : 'span', 'classes' : 'underline', exact : true},
#      strikethrough: {inline : 'del'},
#      forecolor: {inline : 'span', classes : 'forecolor', styles : {color : '%value'}},
#      hilitecolor: {inline : 'span', classes : 'hilitecolor', styles : {backgroundColor : '%value'}},
#      custom_format: {block : 'h1', attributes : {title : 'Header'}, styles : {color : 'red'}}
                style_formats: [
#                    title: 'Блок-ссылка', selector: 'ul', classes: 'blocks-3'
                ]
                allow_html_in_named_anchor: true
                images_upload_url: '/admin/upload'
                relative_urls: false
#                images_upload_handler: (blobInfo, success, failure) ->
#                    xhr = new XMLHttpRequest()
#                    xhr.withCredentials = false
#                    xhr.open 'POST', '/admin/upload'
#
#                    xhr.onload = ->
#                        if xhr.status != 200
#                            return failure 'HTTP Error: ' + xhr.status
#
#                        json = JSON.parse xhr.responseText
#
#                        if not json or typeof json.location != 'string'
#                            return failure 'Invalid JSON: ' + xhr.responseText
#
#                        success json.location
#
#                    formData = new FormData()
#                    formData.append 'file', blobInfo.blob(), blobInfo.filename()
#
#                    xhr.send formData
                automatic_uploads: false
                valid_children : '+a[div]'
                link_list: (cb) =>
                    cb genLinkList @model.root.get 'nodes'
                link_title: false
                target_list: false
                paste_data_images: true
#                paste_preprocess: (pl, o) ->
#                    o.content = strip_tags o.content, '<img><strong><p><a><span>'

            @editor = tinymce.get @elem.id
            @editor.setContent @model.get('value') or ' '
            @elem.addEventListener 'click', (ev) =>
                if @model.root.get '_session.editMode'
                    ev.preventDefault()

            undefined

        @app.page.saveCallbacks[@id] = (cb) =>
            @editor.uploadImages (success) =>
                @model.setDiff 'value', @editor.getContent(), cb
            return

        @model.on 'change', 'value', (value) =>
            @editor?.setContent value or ''

        @on 'destroy', =>
            delete @app.page.saveCallbacks[@id]