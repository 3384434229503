// Generated by CoffeeScript 1.11.1
var derby, path, resolve, util;

path = require('path');

derby = require('derby');

resolve = derby.util.serverRequire(module, 'resolve');

util = require('./util');

derby.use(require('../subcomponents'));

module.exports = function(app, options) {
  var comp, compPath, componentsPath, ctrl, ctrlPath, defaults, dirname, e, fs, globalPaths, i, j, k, key, l, len, len1, len2, len3, len4, len5, len6, len7, len8, m, modelPath, moduleInfo, moduleName, n, name, o, opts, p, pagesPath, q, r, ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, s, v, w, words;
  app.use(require('derby-controller'));
  app.serverUse(module, './sass-auto');
  dirname = path.dirname(app.filename);
  if (derby.util.isServer) {
    fs = derby.util.serverRequire(module, 'fs');
    app.on('bundle', function(bundler) {
      var a, modules, ref;
      modules = Object.values(app.modules).map(function(moduleInfo, moduleName) {
        return [].concat([], moduleInfo.index ? moduleName : [], moduleInfo.opts ? moduleName + '/opts' : [], moduleInfo.components.map(function(p) {
          if (moduleName) {
            return moduleName + '/components/' + p;
          } else {
            return 'components/' + p;
          }
        }), moduleInfo.pages.map(function(p) {
          if (moduleName) {
            return moduleName + '/pages/' + p;
          } else {
            return 'pages/' + p;
          }
        }), moduleInfo.model.map(function(p) {
          if (moduleName) {
            return moduleName + '/model/' + p;
          } else {
            return 'model/' + p;
          }
        }));
      });
      bundler.require((ref = []).concat.apply(ref, modules));
      bundler.require(app.components || []);
      bundler.exclude('app-modules');
      a = new require('stream').Readable();
      a.push('module.exports = ' + JSON.stringify(app.modules));
      a.push(null);
      return bundler.require(a, {
        expose: 'app-modules'
      });
    });
    app.components = options.components;
    app.modules = {};
    globalPaths = module.constructor.globalPaths;
    ref = options.modules;
    for (i = 0, len = ref.length; i < len; i++) {
      moduleInfo = ref[i];
      if (typeof moduleInfo === 'string') {
        moduleInfo = {
          name: moduleInfo
        };
      }
      defaults = {
        path: moduleInfo.name ? resolve.sync(moduleInfo.name, {
          isFile: util.isDirectory,
          paths: globalPaths
        }) : dirname,
        url: '/' + moduleInfo.name,
        components: [],
        pages: [],
        model: []
      };
      for (k in defaults) {
        v = defaults[k];
        if (moduleInfo[k] == null) {
          moduleInfo[k] = v;
        }
      }
      app.modules[moduleInfo.name] = moduleInfo;
      try {
        require.resolve(moduleInfo.name, {
          paths: globalPaths
        });
        moduleInfo.index = true;
      } catch (error) {
        e = error;
        if (e.code !== 'MODULE_NOT_FOUND') {
          throw e;
        }
      }
      try {
        if (moduleInfo.name) {
          require.resolve(moduleInfo.name + '/opts', {
            paths: globalPaths
          });
          moduleInfo.opts = true;
        }
      } catch (error) {
        e = error;
        if (e.code !== 'MODULE_NOT_FOUND') {
          throw e;
        }
      }
      componentsPath = path.join(moduleInfo.path, 'components');
      if (fs.existsSync(componentsPath)) {
        ref1 = fs.readdirSync(componentsPath);
        for (j = 0, len1 = ref1.length; j < len1; j++) {
          name = ref1[j];
          compPath = path.join(componentsPath, name);
          if (fs.statSync(compPath).isDirectory()) {
            moduleInfo.components.push(name);
          }
        }
      }
      pagesPath = path.join(moduleInfo.path, 'pages');
      if (fs.existsSync(pagesPath)) {
        ref2 = fs.readdirSync(pagesPath);
        for (l = 0, len2 = ref2.length; l < len2; l++) {
          name = ref2[l];
          ctrlPath = path.join(pagesPath, name);
          if (fs.statSync(ctrlPath).isDirectory()) {
            moduleInfo.pages.push(name);
          }
        }
      }
      modelPath = path.join(moduleInfo.path, 'model');
      if (fs.existsSync(modelPath)) {
        ref3 = fs.readdirSync(modelPath);
        for (m = 0, len3 = ref3.length; m < len3; m++) {
          name = ref3[m];
          name = path.parse(name).name;
          moduleInfo.model.push(name);
        }
      }
    }
  } else {
    app.modules = require('app-modules');
  }
  ref4 = options.components || [];
  for (n = 0, len4 = ref4.length; n < len4; n++) {
    name = ref4[n];
    app.component(require(name));
  }
  ref5 = options.modules;
  for (o = 0, len5 = ref5.length; o < len5; o++) {
    moduleInfo = ref5[o];
    if (typeof moduleInfo === 'string') {
      moduleInfo = {
        name: moduleInfo
      };
    }
    moduleName = moduleInfo.name;
    ref6 = app.modules[moduleName];
    for (k in ref6) {
      v = ref6[k];
      if (moduleInfo[k] == null) {
        moduleInfo[k] = v;
      }
    }
    if (moduleInfo.index) {
      require(moduleName);
    }
    if (moduleInfo.opts) {
      opts = require(moduleName + '/opts');
      Object.assign(opts, moduleInfo);
    }
    ref7 = moduleInfo.components;
    for (q = 0, len6 = ref7.length; q < len6; q++) {
      name = ref7[q];
      compPath = path.join(moduleInfo.path, 'components', name);
      if (moduleName) {
        comp = require(moduleName + '/components/' + name);
        comp.prototype.name = moduleName + ':' + name;
      } else {
        comp = require('components/' + name);
        comp.prototype.name = name;
      }
      comp.prototype.view = compPath;
      app.component(comp);
    }
    ref8 = moduleInfo.pages;
    for (r = 0, len7 = ref8.length; r < len7; r++) {
      name = ref8[r];
      ctrlPath = path.join(moduleInfo.path, 'pages', name);
      if (moduleName) {
        ctrl = require(moduleName + '/pages/' + name);
        ctrl.prototype.name = moduleName + ':pages:' + name;
      } else {
        ctrl = require('pages/' + name);
        ctrl.prototype.name = 'pages:' + name;
      }
      ctrl.prototype.view = ctrlPath;
      if (typeof ctrl.prototype.path === 'string') {
        ctrl.prototype.path = moduleInfo.url + ctrl.prototype.path || '/';
      } else {
        ctrl.prototype.path = (function() {
          var len8, ref9, results, s;
          ref9 = ctrl.prototype.path || [];
          results = [];
          for (s = 0, len8 = ref9.length; s < len8; s++) {
            p = ref9[s];
            results.push(moduleInfo.url + p);
          }
          return results;
        })();
      }
      app.controller(ctrl);
    }
    ref9 = moduleInfo.model;
    for (s = 0, len8 = ref9.length; s < len8; s++) {
      name = ref9[s];
      words = (function() {
        var len9, ref10, results, t;
        ref10 = name.split(/[-_]/);
        results = [];
        for (t = 0, len9 = ref10.length; t < len9; t++) {
          w = ref10[t];
          results.push(w[0].toUpperCase() + w.slice(1));
        }
        return results;
      })();
      key = words.join('');
      app.proto[key] = require(moduleName + '/model/' + name);
    }
  }
  app.loadStyles(dirname + '/styles');
  return app.loadViews(dirname + '/views');
};
