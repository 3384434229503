require 'core-js/es7/object'
require 'core-js/modules/es6.array.find'
require 'core-js/modules/es6.object.assign'
require 'core-js/modules/es6.string.starts-with'

#async = require 'async'

derby = require 'derby'
#global.$ = global.jQuery = require 'jquery'

#if not derby.util.isServer
#    document.addEventListener 'click', (ev) ->
#            if app.model.get('_session.editMode') and ev.target.tag == 'A'
#                ev.preventDefault()
#        , true

module.exports = app = derby.createApp 'milon', __filename

app.serverUse module, 'derby-jade', coffee: true
app.serverUse module, './styles-file'
app.use require 'derby-debug'
#app.use require 'derby-client-error/lib/app'
#app.loadViews __dirname + '/views'
#app.loadStyles __dirname + '/styles'

app.use require('derby-convention'),
    components: [
#        'd-connection-alert'
#        'd-before-unload'
        'd-loading-indicator'
        'd-a'
#        'd-form'
#        'd-selectize'
#        'd-bs-datepicker'
    ]
    modules: [
        ''
    ]


#app.component 'BodyElement', class
#    init: ->
#        @_events = _.clone @_events
#        @page.main = @
#        @_scope = ['_page']
#        @model = @model.scope '_page'
#        @model.data = @model.get()
#        @model.ref '$render', @model.scope '$render'

Controller = require 'derby/lib/Controller'
expressions = require 'derby-templates/lib/expressions'

setPageScope = (page) ->
    return if page._scope

    page.model = page.model.scope '_page'
    page.model.ref '$render', page.model.scope '$render'
    page.model.data = page.model.get()
    page.model.data.$controller = page

    page.on 'destroy', ->
        @model = @model.root

    if derby.util.isServer
        _destroy = page.model.destroy
        page.model.destroy = (args...) ->
            if args[0] == '$components'
                @root.destroy args...
            else
                _destroy.apply this, args

    page._scope = ['_page']
    page.context.controller = new Controller app, page, page.model.root
    page.context = page.context.componentChild page
    page.context.alias = '#page'
    page.context.expression = new expressions.PathExpression ['_page']

    app.emit 'page', page


app.on 'route', setPageScope
app.on 'ready', setPageScope

app.on 'route', ->
    return unless @model
    connection = @model.get '$connection'
    return if connection.state == 'connected' and 'reason' of connection
#    # Prevent reloading on failed attach refresh
#    return if @page.params.url == @model.get '$render.url'
    window.location.href = window.location.href


app.on 'ready', ->
    if window.Ya?.Metrika
        try
            window.yaCounter = new Ya.Metrika
                id: 26062323
                clickmap: true
                trackLinks: true
                accurateTrackBounce: true
                webvisor: true
                trackHash: true
    else
        (window.yandex_metrika_callbacks ||= []).push ->
            try
                window.yaCounter = new Ya.Metrika
                    id: 26062323
                    clickmap: true
                    trackLinks: true
                    accurateTrackBounce: true
                    webvisor: true
                    trackHash: true

    window['GoogleAnalyticsObject'] = 'ga'
    window.ga ||= ->
        (window.ga.q ||= []).push arguments
    window.ga.l = 1 * new Date()

    ga 'create', 'UA-77057320-1', 'auto'
    ga 'send', 'pageview'

app.on 'routeDone', ->
    return if derby.util.isServer
    if window.yaCounter
        window.yaCounter.hit window.location.href
    else
        window.yandex_metrika_callbacks.push ->
            yaCounter.hit window.location.href

    ga 'set', 'page', location.pathname
    ga 'send', 'pageview'


app.on 'ready', ->
    # Ignore client attach errors. Loaded page can be broken, interactive functions may not work. But it won't be rerendered.
    # Rerendering can take much time if there is a problem with racer connection. Problem can be caused, for example,
    # if another tab is making unanswered WebSocker connection.
    if not derby.util.isServer
        derby.util.isProduction = false

SiteNode = require 'site-node'
app.controller SiteNode
app.controller class
    path: '*'
    handlePost: true
    $model: [
        nodesCollection: $collection: 'nodes'
        menusCollection: $collection: 'menus'
        glboalsCollection: $collection: 'globals'
        ->
            @model.ref 'nodes', @model.scope 'nodes'
            @model.ref 'globals', @model.scope 'globals'
            @model.ref 'menus', @model.scope 'menus'
            rootNode = Object.values(@model.root.get 'nodes').find (n) -> n.url == '/'
            @model.ref 'rootNode', "nodes.#{rootNode.id}"
            {}
    ]

    $render: (next) ->
        nodes = @model.get 'nodes'
        node = Object.values(nodes).find (n) => n.url == @params[0]
        return next() unless node
        @model.ref 'node', @model.at "nodes.#{node.id}"
        cls =
            if node.type
                app._pages['pages:'+node.type]
            else
                SiteNode

        @setClass cls
        @controller next
        true


app.on 'page', (page) ->
    page.saveCallbacks = {}

    page.model.start 'breadcrumbs', 'node.url', (url) ->
        nodes = page.model.get 'nodes'
        return unless url
        slugs = url.split('/')[1..-2]
        breadcrumbUrl = ''
        breadcrumbs = []
        for slug in slugs
            breadcrumbUrl += '/' + slug
            breadcrumb = Object.values(nodes).find (n) -> n.url == breadcrumbUrl
            if breadcrumb
                breadcrumbs.push breadcrumb.id
        breadcrumbs

app.proto.create = ->
#    @model.start '$render.ns', '_page.node.type', (value) -> 'pages:' + value
#    @model.start '$render.prefix', '$render.ns', (value) -> value + ':'

    @model.start '_urlChange', 'node.url', (url) =>
        return unless @model.get 'node'
        return if url == @model.get '$render.url'
        @model.set '$render.url', url
        @app.history.replace url, false


    @model.on 'change', 'node.contentId', (value, oldValue) =>
        @model.root.subscribe "contents.#{value}", =>
            @model.ref 'content', @model.scope "contents.#{value}"
            @dataRefs?['_page.content'] = "contents.#{value}"
            @model.root.unsubscribe "contents.#{oldValue}"

    @model.start '_setEditMode', @model.scope('_session.editMode'), (editMode) =>
        if editMode
            @dataRefs = {}
#            @dataRefRefs = {}
            for from, ref of @model.root._refs.fromMap
                continue if ref.to[0] in '_$'
                @dataRefs[from] = ref.to
#                for r in @model.root._refs.parentToMap[from] or []
#                    @dataRefRefs[r.from] = r.to

            silentModel = @model.root.silent true
            @changes = {}
            @changeListeners = []
            for from, to of @dataRefs
                value = @model.root.getDeepCopy to
                silentModel.removeRef from
                silentModel.set from, value
#                @model.set '$copy.' + to, value
#                @model.ref from, '$copy.' + to
                @changes[from] = {}
                do (from) =>
                    listener = @model.root.on 'all', from + '.**', (p, action, value, oldValue) =>
                        console.log 'change', from, p, arguments
                        @changes[from][p] = true
                    @changeListeners.push listener

                for r in @model.root._refs.parentToMap[from]?[..] or []
                    silentModel.ref r.from, r.to

#            for from, to of @dataRefRefs
#                silentModel.ref from, to
        else
            for listener in @changeListeners or []
                @model.root.removeListener 'all', listener
            for from, to of @dataRefs
                @model.root.ref from, to
        return

    @ready?()
    

app.proto.saveContent = (cb) ->
    if not Object.keys(@changes['_page.content'] or {}).length
        return cb()
    currentContent = @model.root.getDeepCopy @dataRefs['_page.content']
    if currentContent
        delete currentContent.id
    @model.set 'currentContent', currentContent
    for p of @changes['_page.content']
        @model.set "currentContent.#{p}", @model.get "content.#{p}"
    @changes['_page.content'] = {}
    currentContent = @model.get 'currentContent'
    @model.del 'currentContent'
    id = @model.root.add 'contents', currentContent, cb
    @model.set 'node.contentId', id
    @dataRefs['_page.content'] = "contents.#{id}"
    console.log 'content saved', id

app.proto.save = ->
    async.series @page.saveCallbacks, (err) =>
        # This callback is called synchronously after Last saveCallback. Model 'all' listener is called asynchronously.
        async.nextTick =>
            @saveContent =>
                async.eachOf @changes, (changes, from, cb) =>
                        async.eachOf changes, (value, p, cb) =>
                                console.log 'save change', "#{@dataRefs[from]}.#{p}", @model.root.get("#{from}.#{p}")
                                @model.root.setDiffDeep "#{@dataRefs[from]}.#{p}", @model.root.get("#{from}.#{p}"), cb
                            , cb
                    , => @model.root.set '_session.editMode', false

#app.proto._ = _
app.proto.model = app.model

app.proto.nodeHasGrandchild = (node, nodes) ->
    node?.children?.some (id) ->
        nodes[id].children?[0]

app.proto.headerColor = (node, breadcrumbs, nodes) ->
    return node?.headerColor if not node or node.headerColor
    reversedBreadcrumbs = breadcrumbs[..].reverse()
    for id in reversedBreadcrumbs
        return nodes[id].headerColor if nodes[id].headerColor
    ''
    
app.on 'render', ->
    window?.scrollTo 0, 0

app.proto.slug =
    get: (url) ->
        parts = url?.split('/')
        parts?[parts.length - 1]

    set: (slug, url) ->
        [ url.split('/')[..-2].concat(slug).join '/' ]


app.get '/sitemap.xml', ->
    @res.type 'xml'

    
    @modifiedDate = (timestamp) ->
        new Date(timestamp or 0).toISOString()[..9]

    @renderStatic 'sitemap'