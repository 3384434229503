// Generated by CoffeeScript 1.11.1
var derby, fs;

derby = require('derby');

fs = derby.util.serverRequire(module, 'fs');

module.exports = {
  isDirectory: function(file) {
    var err, stat;
    try {
      stat = fs.statSync(file);
    } catch (error) {
      err = error;
      if (err && err.code === 'ENOENT') {
        return false;
      }
    }
    return stat.isDirectory();
  }
};
