module.exports = class extends require 'site-node'
    $model: ->
        Object.assign super(),
            colors: $set:
                frame: 'darkiron'
                casing: 'silver'
                upholstery: 'anthracite'

            activePhoto: $set: 0
            machines: $set: [
                name: 'circuit'
            ,
                name: 'leg_extension'
                title: 'Разгибание ног'
            ,
                name: 'chest_press'
                title: 'Жим от груди'
            ,
                name: 'seated_rowing'
                title: 'Тяга сидя'
            ,
                name: 'crosstrainer'
                title: 'Эллипс'
                upholstery: false
            ,
                name: 'leg_curl'
                title: 'Сгибание ног'
            ,
                name: 'back_extension'
                title: 'Разгибание спины'
            ,
                name: 'abdominal_crunch'
                title: 'Скручивание на пресс'
            ,
                name: 'bike'
                title: 'Велотренажер'
                upholstery: false
            ]
